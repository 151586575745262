/* Provide sufficient contrast against white background */


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

video
{
    object-fit: contain;
    aspect-ratio: 16/9;

}